import { Spacing } from "./Content/Components/Spacing";
import { LogoSvg } from "./assets/svg/LogoSvg";

const firebaseConfig = {
  apiKey: "AIzaSyDzvJkuMbxvGNOGm1KHwjD8YaqHEhiHNmw",
  authDomain: "nycrow.firebaseapp.com",
  databaseURL: "https://nycrow-default-rtdb.firebaseio.com",
  projectId: "nycrow",
  storageBucket: "nycrow.appspot.com",
  messagingSenderId: "290542647305",
  appId: "1:290542647305:web:4320bba8379fa7ead760d8",
  measurementId: "G-G8Y1088MNR",
};

const App = () => {
  return (
    <div
      style={{
        // width: "100vh",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoSvg
        style={{
          width: 200,
        }}
      />
      <Spacing spacing={40} />
      <div
        style={{
          // textAlign: "center",
          maxWidth: 400,
        }}
      >
        <h2>Closure Announcement</h2>
        <h3>Effective June 17th, giv.ink will be ceasing operations.</h3>
        <p>
          All processed donations will be remitted to the specified bank
          accounts of the respective organizations.
        </p>
        <p>
          We extend our heartfelt gratitude for your support over the years, and
          we hope to connect with you again in the future.
        </p>
        <p>
          If you are a donor and have reached this page, please contact the
          organization you support to inform them of our closure.
        </p>
        <Spacing spacing={20} />
        <p>
          For any further support, please contact us at{" "}
          <a
            style={{
              color: "grey",
              textDecoration: "underline",
            }}
            href="mailto:admin@nycroft.com"
          >
            admin@nycroft.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default App;
