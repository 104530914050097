export const COLORS = {
  RED: "#ff5c5c",
  BLUE: "#0082f3",
  BLACK: "#343434",
  EXTRABLACK: "rgb(29 29 29)",
  YELLOW: "#ffab1a",
  DEFAULT: "#F6F6F6",
  DARKERDEFAULT: "#e4e4e4",
  WHITE: "white",
  GREY: "grey",
  LIGHTGREY: "rgb(224 224 224)",
  LIME: "#00c88b",
  BRAND: "#343434",
  ALMOST_WHITE: "rgb(251 251 251)",
  SOFTGREY: "rgb(231, 231, 231)",
  STRIPEWHITE: "rgb(249 251 253)",
};
