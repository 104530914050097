export const interval = {
  0: 0,
  1: 1,
  2: 2,
  5: 5,
  10: 10,
  15: 15,
  20: 20,
  25: 25,
};
